@import url(https://fonts.googleapis.com/css?family=Covered+By+Your+Grace);
body {
  margin: 0;
  padding: 0;
  font-family: 'HarmoniaSansProCyr-Regular', sans-serif;
}

code {
  font-family: 'HarmoniaSansProCyr-Regular', sans-serif;
}
/*@font-face {*/
    /*font-family: 'HarmoniaBureauSansProCyr-SemiBd';*/
    /*src:url(./fonts/HarmoniaBureauSansProCyr.eot);*/
    /*src:url(./fonts/HarmoniaBureauSansProCyr.eot?#iefix) format("embedded-opentype"),url(./fonts/HarmoniaBureauSansProCyr.woff) format("woff"),url(./fonts/HarmoniaBureauSansProCyr.ttf) format("truetype"),url(./fonts/HarmoniaBureauSansProCyr.svg#HarmoniaBureauSansProCyr) format("svg");*/
    /*font-style: normal;*/
    /*font-weight: normal;*/
/*}*/

@font-face {
    font-family: 'HarmoniaSansProCyr-Regular';
    src:url(/static/media/HarmoniaSansProCyr-Regular.cbe07c61.eot);
    src:url(/static/media/HarmoniaSansProCyr-Regular.cbe07c61.eot?#iefix) format("embedded-opentype"),url(/static/media/HarmoniaSansProCyr-Regular.8b083f1f.woff) format("woff"),url(/static/media/HarmoniaSansProCyr-Regular.157ba8cb.ttf) format("truetype"),url(/static/media/HarmoniaSansProCyr-Regular.22d942dd.svg#HarmoniaSansProCyr-Regular) format("svg");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'HarmoniaSansProCyr-SemiBd';
    src:url(/static/media/HarmoniaSansProCyr-SemiBd.f07a5c38.eot);
    src:url(/static/media/HarmoniaSansProCyr-SemiBd.f07a5c38.eot?#iefix) format("embedded-opentype"),url(/static/media/HarmoniaSansProCyr-SemiBd.620042ea.woff) format("woff"),url(/static/media/HarmoniaSansProCyr-SemiBd.8f730d38.ttf) format("truetype"),url(/static/media/HarmoniaSansProCyr-SemiBd.7ede8cbe.svg#HarmoniaSansProCyr-SemiBd) format("svg");
    font-style: normal;
    font-weight: normal;
}

@font-face {
  font-family: 'HarmoniaBureauSemiBold';
  src: url(/static/media/HarmoniaBureauSemiBold.b9d77a7b.eot);
  src: local('\263A'), url(/static/media/HarmoniaBureauSemiBold.8e059daa.woff) format('woff'), url(/static/media/HarmoniaBureauSemiBold.a8ad733b.ttf) format('truetype'), url(/static/media/HarmoniaBureauSemiBold.4df87f4e.svg) format('svg');
  font-weight: normal;
  font-style: normal;
}




/*--all page--*/
*{
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  box-sizing: border-box;
}

html,
body{
  width: 100%;
  box-sizing: border-box;
  min-width: 320px;
}

html.smooth,
html.smooth body{
  scroll-behavior: smooth;
}

body.unscrollable{
  overflow: hidden;
}

body{
  color: #ffffff;
  background-color: #000000;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

input{
  background: transparent;
}

h1, h2, h3, p{
  margin: 0;
  padding: 0;
  font-weight: normal;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover,
a:visited,
a:active,
a:focus {
  text-decoration: none;
}

ul{
  margin: 0;
  padding: 0;
}

li{
  list-style-type: none;
}

.disabled{
  pointer-events: none;
  opacity: 0.5;
}

.unscrollable{
  overflow: hidden;
}

.wrap{
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 60px;
  box-sizing: border-box;
  background-color: transparent;
  position: relative;
}

.wrap--indent {
  max-width: 1440px;
  width: 100%;
  padding: 0 120px;
  margin: 0 auto;
}

.wrap--wide{
  padding: 0 60px;
}

/*--btn--*/
.btn{
  display: inline-block;
  font-family: 'HarmoniaSansProCyr-SemiBd', sans-serif;
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0;
  border: none;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  padding: 0;
}

.btn:active{
  box-shadow: none;
}

/*--btn--*/
/*--btn--white--*/
.btn--white{
  color: #000000;
  background: #ffffff;
  line-height: 48px;
  padding: 0 48px 0 49px;
}

.btn--white:hover,
.btn--white:active {
  color: #3b4df3;
}

/*--end btn--white--*/
/*--btn--transparent*/
.btn--transparent{
  line-height: 45px;
  padding: 1px 49px 0 48px;
  background-color: transparent;
  border: solid 2px #ffffff;
}

.btn--transparent:hover{
  color: #000000;
  background-color: #ffffff;
}

.btn--transparent:active{
  color: #3b4df3;
}

/*--end btn--transparent*/
/*--btn--DarkBlue--*/
.btn--DarkBlue{
  line-height: 50px;
  color: #ffffff;
  background-color: #3b4df3;
  padding: 0 50px 0 51px;
}

.btn--DarkBlue:hover,
.btn--form:hover{
  background-color: #5483fe;
  color: #ffffff;
}

.btn--DarkBlue:active{
  background-color: #2734aa;
  color: #ffffff;
}

/*--end btn--DarkBlue--*/
/*--end all page--*/

/*--header--*/
.header-wrapper {
  background: transparent;
  width: 100%;
  position: fixed;
  z-index: 1000;
  top: 0;
}
.header-wrapper.transparent{
  background: transparent;
}

.header{
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 40px 0 31px;
  box-sizing: border-box;
}

.nvg {
  display: flex;
}

.nvg-list{
  font-family: 'HarmoniaSansProCyr-SemiBd', sans-serif;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.1px;
}

.nvg-list:not(:last-child){
  margin-right: 20.5px;
}

.company{
  font-family: 'HarmoniaSansProCyr-SemiBd', sans-serif;
  font-size: 20px;
  letter-spacing: 0.1px;
  font-stretch: normal;
  line-height: 1;
}

.menu button{
  display: none;
}
.menu .company{
  display: none;
}

.menu .btn {
  display: none;
}

.nvg .nvg-list a svg{
  display: none;
}

.dark .menu .btn,
.light .menu .btn{
  display: inline-block;
}

.header-wrapper.dark .menu,
.header-wrapper.light .menu{
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 250px;
}

.header-wrapper.dark .menu .btn,
.header-wrapper.light .menu .btn{
  position: absolute;
  right: 0;
  bottom: -16px;
}

.header-wrapper.dark{
  background: #000;
}
.header-wrapper.light{
  background: #fff;
}

.header-wrapper.light .menu,
.header-wrapper.light .company{
  color: #000;
}

@media (max-width: 1023px) {
  .header{
    padding: 28px 0 15px;
    height: 65px;
  }
  .company{
    font-size: 18px;
  }
  .opened .left-panel{
    -webkit-animation: menu-show 0.5s forwards;
            animation: menu-show 0.5s forwards;
  }
  .closed .left-panel{
    -webkit-animation: menu-hide 0.5s forwards;
            animation: menu-hide 0.5s forwards;
  }
  .left-panel {
    padding: 32px 25px;
    box-sizing: border-box;
    position: fixed;
    right: -60%;
    top: 0;
    height: 100vh;
    width: 55%;
    background: #fff;
  }
  .menu .company{
    display: none;
  }

  .burger-btn{
    width: 18px;
    height: 18px;
    display: inline-block;
    background: inherit;
    cursor: pointer;
  }

  .header-wrapper.light .burger-btn{
    -webkit-filter: invert(100%);
            filter: invert(100%);
  }

  .nvg {
    flex-direction: column;
    color: #000;
    z-index: 1;
    margin-right: 0;
  }

  .nvg .nvg-list{
    padding: 12px 0;
    font-size: 18px;
    font-family: 'HarmoniaSansProCyr-Regular', sans-serif;
    margin-right: 0;
  }

  .header-wrapper.dark .menu,
  .header-wrapper.light .menu{
    padding-right: 0;
  }

  @-webkit-keyframes menu-show {
    from {
      right: -60%;
    }
    to {
      right: 0;
    }
  }

  @keyframes menu-show {
    from {
      right: -60%;
    }
    to {
      right: 0;
    }
  }
  @-webkit-keyframes menu-hide{
    from {
      right: 0;
    }
    to {
      right: -60%;
    }
  }
  @keyframes menu-hide{
    from {
      right: 0;
    }
    to {
      right: -60%;
    }
  }
}

@media (max-width: 767px) {
  .header{
    padding-top: 21px;
    height: 54px;
  }
  .left-panel {
    padding: 24px;
    right: -100%;
    width: 100%;
  }

  .nvg{
    margin-top: 44px;
  }

  .nvg .nvg-list{
    position: relative;
  }
  .nvg .nvg-list a{
    display: block;
  }
  .nvg .nvg-list a svg{
    width: 11px;
    height: 16px;
    display: block;
    position: absolute;
    right: 0;
    top: 15px;
    background: inherit;
  }

  .left-panel .company{
    display: inline-block;
    color: #000;
  }

  @-webkit-keyframes menu-show {
    from {
      right: -100%;
    }
    to {
      right: 0;
    }
  }

  @keyframes menu-show {
    from {
      right: -100%;
    }
    to {
      right: 0;
    }
  }
  @-webkit-keyframes menu-hide{
    from {
      right: 0;
    }
    to {
      right: -100%;
    }
  }
  @keyframes menu-hide{
    from {
      right: 0;
    }
    to {
      right: -100%;
    }
  }
}
/*--end header--*/
/*--Particles--*/
.particles{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: 'HarmoniaSansProCyr-SemiBd', sans-serif;
  height: 100vh;
  margin-bottom: 0;
}

.btn--particles {
  margin-top: 51px;
}

.particles__capt{
  font-size: 120px;
  line-height: normal;
  letter-spacing: 0.3px;
  margin-bottom: 0;
  color: transparent;

}

.particles__capt .visible{
  -webkit-animation: show-letters 500ms forwards;
          animation: show-letters 500ms forwards;
}

@-webkit-keyframes show-letters {
  from {
    color: transparent;
  }
  to {
    color: #fff;
  }
}

@keyframes show-letters {
  from {
    color: transparent;
  }
  to {
    color: #fff;
  }
}

.particles__dsc {
  max-width: 730px;
  font-size: 20px;
  font-stretch: normal;
  letter-spacing: 0.05px;
  margin-top: 100px;
  opacity: 0;
  -webkit-animation: particles-desc-fade 1s 0.25s forwards;
          animation: particles-desc-fade 1s 0.25s forwards;
}

.btn--particles{
  opacity: 0;
  -webkit-animation: particles-btn-fade 1s 0.25s forwards;
          animation: particles-btn-fade 1s 0.25s forwards;
}

/*--Scroll-down--*/
.scrollDown{
  display: flex;
  align-items: center;
  position: absolute;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  -webkit-transform-origin: top right;
          transform-origin: top right;
  top: 50%;
  margin-top: -207px;
  right: 68px;
  opacity: 0;
  -webkit-animation: scroll-fade 0.5s 1s forwards;
          animation: scroll-fade 0.5s 1s forwards;
}

.scrollDown svg{
  background: inherit;
  width: 30px;
  height: 5px;
  display: inline-block;
  margin-right: -5px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.scrollDown__txt{
  font-family: 'HarmoniaSansProCyr-Regular', sans-serif;
  font-size: 14px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0;
  text-align: right;
  color: #ffffff;
  margin-left: 8px;
}

.scrollDown__line-with-arrow{
  position: absolute;
  right: 100%;
  top: 5px;
  display: flex;
  align-items: center;
}

.scrollDown__line{
  width: 321px;
  height: 1px;
  opacity: 0.5;
  background-color: #ffffff;
  -webkit-animation: scroll-appear 2s 300ms forwards;
          animation: scroll-appear 2s 300ms forwards;
}

@-webkit-keyframes scroll-fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scroll-fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes scroll-appear {
  from {
    opacity: 0;
    width: 100px;
  }
  50% {
    opacity: 0.25;
    width: 100px;
  }
  to {
    opacity: 0.5;
    width: 321px;
  }
}

@keyframes scroll-appear {
  from {
    opacity: 0;
    width: 100px;
  }
  50% {
    opacity: 0.25;
    width: 100px;
  }
  to {
    opacity: 0.5;
    width: 321px;
  }
}

/*--end scroll-down--*/

/*--particles background--*/
.particles__content{
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: -29px;
}
.particles-background{
  position: absolute;
  z-index: 0;
  opacity: 0;
  width: 0;
  height: 0;
  -webkit-animation: particles-fade 1.5s forwards;
          animation: particles-fade 1.5s forwards;
}

@-webkit-keyframes particles-fade {
  from {
    opacity: 0;
    width: 0;
    height: 0;
  }
  to {
    opacity: 1;
    height: 800px;
    width: 1000px;
    top: 50%;
    left: 50%;
    margin-left: -500px;
    margin-top: -400px;
  }
}

@keyframes particles-fade {
  from {
    opacity: 0;
    width: 0;
    height: 0;
  }
  to {
    opacity: 1;
    height: 800px;
    width: 1000px;
    top: 50%;
    left: 50%;
    margin-left: -500px;
    margin-top: -400px;
  }
}

@-webkit-keyframes particles-desc-fade {
  from {
    opacity: 0;
    margin-top: 100px;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
}

@keyframes particles-desc-fade {
  from {
    opacity: 0;
    margin-top: 100px;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
}

@-webkit-keyframes particles-btn-fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes particles-btn-fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/*--end particles background--*/
@media (min-width: 1024px) and (max-width: 1439px) {
  .wrap,
  .wrap--wide{
    padding: 0 40px;
  }
  .particles__capt{
    font-size: 80px;
    letter-spacing: 0.2px;
  }
  .particles__dsc{
    margin: 8px 80px 0;
  }
}

@media (max-width: 1023px) {
  .wrap,
  .wrap--wide{
    padding: 0 24px;
  }

  .scrollDown{
    display: none;
  }

  @-webkit-keyframes particles-desc-fade {
    from {
      opacity: 0;
      margin-top: 100px;
    }
    to {
      opacity: 1;
      margin-top: 8px;
    }
  }

  @keyframes particles-desc-fade {
    from {
      opacity: 0;
      margin-top: 100px;
    }
    to {
      opacity: 1;
      margin-top: 8px;
    }
  }

  @-webkit-keyframes particles-fade {
    from {
      opacity: 0;
      width: 0;
      height: 0;
    }
    to {
      opacity: 1;
      height: 580px;
      width: 768px;
        top: 50%;
        left: 50%;
        margin-left: -384px;
        margin-top: -290px;
    }
  }

  @keyframes particles-fade {
    from {
      opacity: 0;
      width: 0;
      height: 0;
    }
    to {
      opacity: 1;
      height: 580px;
      width: 768px;
        top: 50%;
        left: 50%;
        margin-left: -384px;
        margin-top: -290px;
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {

  .particles__capt{
    font-size: 60px;
  }
  .particles__dsc{
    max-width: 520px;
    margin: 8px 0 0;
    font-size: 18px;
  }
  .btn--particles{
    margin-top: 44px;
  }
  .particles__content{
    margin-top: 70px;
  }
}

@media (max-width: 767px) {
  .particles{
    height: auto;
  }
  .particles__capt{
    font-size: 28px;
    letter-spacing: 0.1px;
  }
  .particles__dsc{
    margin: 8px 0 0;
    font-size: 16px;
    line-height: 24px;
    padding: 0 24px;
  }
  .btn--particles{
    margin-top: 41px;
    width: 100%;
    line-height: 40px;
  }
  .particles__content {
    margin-top: 168px;
  }

  @-webkit-keyframes particles-fade {
    from {
      opacity: 0;
      width: 0;
      height: 0;
    }
    to {
      opacity: 1;
      height: 250px;
      width: 320px;
        top: 50%;
        left: 50%;
        margin-left: -160px;
        margin-top: -125px;
    }
  }

  @keyframes particles-fade {
    from {
      opacity: 0;
      width: 0;
      height: 0;
    }
    to {
      opacity: 1;
      height: 250px;
      width: 320px;
        top: 50%;
        left: 50%;
        margin-left: -160px;
        margin-top: -125px;
    }
  }
}

/*--end Particles--*/
/*--BuroDataTxt--*/
.BuroDataTxt{
  display: flex;
}

.BuroDataTxt--indent{
  margin-bottom: 183px;
  margin-top: 40vh;
}

.BuroDataTxt__capt{
  width: 34%;
  font-family: 'HarmoniaSansProCyr-SemiBd', sans-serif;
  font-size: 14px;
  line-height: 50px;
  letter-spacing: 0.8px;
}

.BuroDataTxt__desc{
  width: 66%;
  position: relative;
  font-family: 'HarmoniaBureauSemiBold', sans-serif;
  font-size: 28px;
  line-height: 1.60;
  letter-spacing: 0.6px;
}

/*--end BuroDataTxt--*/
/*--ClassicScheme--*/
.ClassicScheme{
  display: flex;
  position: relative;
  max-width: 1440px;
  width: 100%;
  margin: auto;
}

.ClassicScheme__bg-mono {
  width: 529px;
  height: 700px;
  background-color: transparent;
  position: relative;
}

.ClassicScheme__bg-mono.animate:after{
  content: "";
  width: 100%;
  height: 0;
  position: absolute;
  top:0;
  left: 0;
  background: #2734aa;
  -webkit-animation: color-appear 0.75s forwards;
          animation: color-appear 0.75s forwards;
}

.ClassicScheme__bg-img{
  width: 912px;
  height: 700px;
  object-fit: cover;
}

@-webkit-keyframes color-appear {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}

@keyframes color-appear {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}

/*--SecondaryCaption--*/
.SecondaryCaption{
  position: absolute;
  top: 96px;
  left: 219px;
  z-index: 10;
}

.SecondaryCaptionTitle{
  width: 621px;
  font-family: 'HarmoniaBureauSemiBold', sans-serif;
  font-size: 60px;
  line-height: 1.2;
}

.SecondaryCaptionTitle:before{
  content: '\2014';
  font-size: 60px;
  line-height: 0.93;
}
.SecondaryCaptionTitle--ClassicScheme:before{
  position: absolute;
  top: 6px;
  left: -99px;
}

.SecondaryCaptionTxt{
  font-family: 'HarmoniaSansProCyr-Regular', sans-serif;
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: 0;
}

.SecondaryCaptionTxt--indent{
  margin-top: 23px;
}

.SecondaryCaption--SecColor{
  color: #000000;
}
/*--end SecondaryCaption--*/

.btn--ClassicScheme-indent {
  margin-top: 58px;
  margin-left: 3px;
}

/*--end ClassicScheme--*/
/*--Approach--*/
.Approach{
  display: flex;
  margin-top: 195px;
}

.Approach__ApproachCapt{
  width: 46%;
  font-family: 'HarmoniaSansProCyr-SemiBd', sans-serif;
  font-size: 40px;
  line-height: 1.2;
  letter-spacing: 0.1px;
  margin-top: 5px;
}

.ApproachTxt__capt{
  width: 75%;
  font-family: 'HarmoniaSansProCyr-Regular', sans-serif;
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: 0.4px;
  margin-bottom: 31px;
}

.ApproachTxt__list{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 205px;
  font-family: 'HarmoniaSansProCyr-SemiBd', sans-serif;
  font-size: 18px;
  letter-spacing: 0.1px;
}
.ApproachTxt__list:first-child{
  margin-right: 24px;
}

.ApproachTxt__list-block{
  display: flex;
  flex-direction: column;
}

.ApproachTxt__list-block span{
  margin-bottom: 15px;
}

.ApproachTxt__list > *{
  width: 47%;
}

.ApproachTxt__list-block span:before{
  content: "\2014";
  display: inline-block;
  font-family: 'HarmoniaSansProCyr-SemiBd', sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: 0.1px;
  margin-right: 4px;
}

/*-end Approach--*/
/*--BuroDataTransf--*/
.BuroDataTransf{
  position: relative;
  padding-bottom: 249px;
}

.BuroDataTransf__desc{
  width: calc(66% + 120px);
  border-bottom: 5px solid #3b4df3;
  padding: 0 120px 35px 0;
  margin-right: -120px;
}

.BuroDataTransf--indent{
  margin-top: 167px;
}

/*--end BuroDataTransf--*/
/*--Capability--*/
.Capability{
  background-color: #ffffff;
  text-align: center;
}

.CapabilityTxt{
  position: relative;
  width: 100%;
  height: 464px;
  text-align: left;
}

.CapabilityPlatformWhite{
  max-width: 1440px;
  width: 100%;
  margin: auto;
  height: auto;
  object-fit: contain;
}

.SecondaryCaption--CapabilityIndent{
  top: 142px;
  left: 220px;
}

.SecondaryCaptionTitle--Capability:before{
  position: absolute;
  top: 8px;
  left: -102px;
}

.btn--DarkBlue-indent{
  margin-top: 50px;
}

/*--end Capability--*/
/*--Spheres--*/
.Spheres{
  background-color: #ffffff;
  padding-top: 50px;
}

.Spheres__capt{
  width: 600px;
  font-family: 'HarmoniaSansProCyr-SemiBd', sans-serif;
  font-size: 40px;
  line-height: 0.9;
  letter-spacing: 0.1px;
  color: #000000;
  margin-bottom: 55px;
}

.Spheres__block{
  display: flex;
}

.Spheres__blockItem{
  display: flex;
  flex-direction: column;
  font-family: 'HarmoniaSansProCyr-Regular', sans-serif;
  font-size: 18px;
  letter-spacing: 0;
  color: #000000;
}

.Spheres__blockItem li{
  display: flex;
  align-items: baseline;
}

.Spheres__blockItem li:before{
  content: "\2014";
  display: inline-block;
  font-size: inherit;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: 0.1px;
  margin-right: 4px;
}

.Spheres__blockItem:nth-child(1){
  margin-right: 229px;
}

.Spheres__blockItem:nth-child(2){
  margin-right: 203px;
}

.Spheres__blockItem li{
  line-height: 49px;
}

/*--end Spheres--*/
/*--Advantages--*/
.Advantages > .wrap{
  display: flex;
  flex-wrap: wrap;
}

.Advantages{
  background-color: #ffffff;
  font-family: 'HarmoniaSansProCyr-SemiBd', sans-serif;
  padding-top: 138px;
  padding-bottom: 88px;
}

.AdvantagesIco{
  display: inline-block;
  background-position: center;
  background-size: cover;
  margin-bottom: 18px;
  width: 120px;
  height: 120px;
}

.Advantages__title{
  font-family: 'HarmoniaBureauSemiBold', sans-serif;
  width: 486px;
  font-size: 60px;
  line-height: 1.2;
  letter-spacing: 0.1px;
  color: #000000;
  margin-right: 125px;
  margin-top: 12px;
}

.AdvantagesCapt{
  width: 486px;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: 0.1px;
  color: #000000;
  margin-bottom: 25px;
}

.AdvantagesDsc{
  display: flex;
}

.Advantages__block{
  width: 486px;
  margin-bottom: 92px;
}

.Advantages__block:nth-child(odd){
  margin-right: 126px;
}

.AdvantagesDsc__Dash{
  position: relative;
  top: 7px;
  width: 19px;
  height: 2px;
  background-color: #000000;
}

.AdvantagesDsc__Dash--tp8{
  top: 8px;
}

.AdvantagesDsc__Dash--tp4{
  top: 4px;
}

.AdvantagesDsc__Cont{
  width: 453px;
  font-family: 'HarmoniaSansProCyr-Regular', sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  margin-left: 15px;
}

.AdvantagesDsc__Cont span{
  color: #8a8a8a;
}

/*--end Advantages--*/
/*--SliderProj--*/
.SliderProj{
  position: relative;
  padding-bottom: 176px;
}

.Slider--indent{
  margin: 76px 0 55px 0;
}

.SliderTitle{
  display: flex;
  justify-content: space-between;
  padding-top: 135px;
}

.SliderCapt{
  font-family: 'HarmoniaBureauSemiBold', sans-serif;
  font-size: 40px;
  line-height: 1.2;
  letter-spacing: 0.1px;
  color: #ffffff;
}

.SliderCont {
  display: inline-block;
  width: 770px;
  height: 100px;
  font-family: 'HarmoniaSansProCyr-Regular', sans-serif;
  font-size: 100px;
  letter-spacing: 0.3px;
  white-space: nowrap;
}

.SliderCont:not(:first-child){
  cursor: pointer;
}

.SliderProjLink{
  display: inline-block;
  position: relative;
  height: 18px;
  font-family: 'HarmoniaBureauSemiBold', sans-serif;
  font-size: 18px;
  letter-spacing: 0;
  color: #3b4df3;
  margin-bottom: 74px;
  margin-left: 7px;
}

.SliderProjLink:active,
.SliderProjLink:focus,
.SliderProjLink:visited,
.SliderProjLink:hover{
  color: #3b4df3;
}

.SliderProjLink svg{
  position: absolute;
  top: 3px;
  right: -34px;
  width: 18px;
  height: 18px;
}

.SliderProjLink:hover,
.SliderProjLink:visited,
.SliderProjLink:active,
.SliderProjLink:focus {
  color: #3b4df3;
}

.Platform__capt{
  width: 485px;
  font-family: 'HarmoniaSansProCyr-SemiBd', sans-serif;
  font-size: 28px;
  line-height: 1.29;
  letter-spacing: 0.1px;
  margin-bottom: 45px;
}

.Platform__list{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-family: 'HarmoniaSansProCyr-Regular', sans-serif;
  font-size: 18px;
  letter-spacing: 0;
}

.Platform__list span {
  /*margin-bottom: 23px;*/
}

.Platform__list--unite span:nth-child(odd){
  margin-right: 64px;
  /*margin-left: 10px;*/
}

.Platform__list--unite span:nth-child(odd){
  width: 43%;
}

.Platform__list--unite span:nth-child(even){
  width: 38%;
}

.Platform__list--unite span{
  margin-bottom: 15px;
}

.Platform__list--left span{
  max-width: 70%;
  margin-bottom: 23px;
}

.PlatformCombines .Platform__list{
  padding-left: 5px;
}

.SlderBottomBlock{
  display: flex;
  justify-content: space-between;
}

.SlderBottomBlock > * {
  width: 49%;
}

.Platform__list--unite{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 0;
}

.List--indent{
  margin-right: 120px;
}

.SliderBtn__prev,
.SliderBtn__next{
  width: 19px;
  height: 30px;
  cursor: pointer;
  position: absolute;
  z-index: 100;
  top: -47px;
  opacity: 0.5;
}

.SliderBtn__next{
  right: 2px;
}

.SliderBtn__prev{
  right: 61px;
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}

.SliderBtn__prev:hover,
.SliderBtn__next:hover{
  opacity: 1;
}


/*--end Slider btn--*/

/*--test slider--*/
.Slider--indent{
  display: flex;
}

.Slider h2{
  margin-right: 50px;
}

.Slider{
  overflow: hidden;
}

.Slider :not(:first-child){
  color: rgba(255,255,255, 0.3);
}
/*--end test slider--*/


/*-end SliderProj--*/
/*--ApplicationForm--*/
.ApplicationForm{
  display: flex;
  justify-content: space-between;
}

.ApplicationFormCapt{
  width: 30%;
  font-family: 'HarmoniaSansProCyr-SemiBd', sans-serif;
  font-size: 40px;
  line-height: 1.2;
  letter-spacing: 0.1px;
  color: #ffffff;
}

.Form{
  width: 791px;
  font-family: 'HarmoniaSansProCyr-Regular', sans-serif;
  margin-top: -3px;
}

.Form__TopString{
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.Form__TopString .FormGroup{
  width: 382px;
}

.FormGroup,
.Form__txt{
  position: relative;
}

.FormGroup.filled label{
  display: block;
}

.modal-wrapper .FormGroup_error input:not(:focus),
.modal-wrapper .FormGroup_error textarea:not(:focus){
  border-bottom: 1px solid #ee443a;
}

.error{
  color: #ee443a;
  font-size: 12px;
  letter-spacing: 0.3px;
  position: absolute;
  bottom: -24px;
  left: 0;
  display: none;
}

.FormGroup_error input:not(:focus) + .error,
.FormGroup_error textarea:not(:focus) + .error{
  display: block;
}

.FormGroup label{
  position: absolute;
  top: -5px;
  left: 0;
  margin-bottom: 0;
  display: none;
  opacity: 0.5;
  font-family: 'HarmoniaSansProCyr-Regular', sans-serif;
  font-size: 12px;
  letter-spacing: 0.3px;
}

.FormGroup input:focus ~ label,label:active,
.FormGroup input:focus ~ label,label:focus{
  display: block;
}

/*--placeholder color transparent--*/
input:focus::-webkit-input-placeholder { color:transparent; padding-top: 12px;}
input:focus:-moz-placeholder { color:transparent;} /* FF 4-18 */
input:focus::-moz-placeholder { color:transparent;} /* FF 19+ */
input:focus:-ms-input-placeholder { color:transparent;} /* IE 10+ */
/*--end placeholder color transparent--*/

.FormGroup input {
  width: 100%;
  background: transparent;
}

.FormGroup .FormCaptcha{
  width: auto;
  position: relative;
}

.btn--form {
  display: inline-block;
  padding: 0 70px 0 71px;
  line-height: 50px;
  background-color: #3b4df3;
  color: #ffffff;
}

.Form__CompString{
  margin-bottom: 40px;
}
.FormSubmit{
  display: flex;
  align-items: center;
  margin: 34px 0 24px 0;
}

.FormSubmit span{
  display: inline-block;
  width: 132px;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.3px;
  color: #ee443a;
  margin-right: 33px;
}

.FormCaptchaTest{
  width: 179px;
  height: 39px;
  margin-right: 23px;
}

.FormCaptcha{
  width: 180px;
  margin-right: 24px;
}

.FormCaptchaEn{
  width: 260px;
  margin-right: 24px;
}

.FormConsent-label{
  font-family: 'HarmoniaSansProCyr-Regular', sans-serif;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.1px;
  color: #ffffff;
  margin: 0;
  padding-left: 10px;
  cursor: pointer;
}

.FormConsent-label a{
  color: #3b4df3;
}

.FormConsent-label a:hover{
  color: #5483fe;
}

.FormConsent-label:before{
  content: "";
  display: inline-block;
  position: relative;
  width: 22px;
  height: 22px;
  border: solid 1px #ffffff;
  vertical-align: middle;
  background-color: transparent;
  margin-left: -10px;
  margin-right: 10px;
  margin-top: -3px;
}

.FormConsent-checkbox{
  display: none;
}

.FormConsent-checkbox:checked + label::before{
  background: url(/static/media/checked.5f347ae1.svg) no-repeat;
  background-position: center;
}

.capcha{
  font-family: 'Covered By Your Grace', cursive;
  background: #fff;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.reload-capcha{
  width: 22px;
  height: 22px;
  background: #fff;
  margin-left: 18px;
  cursor: pointer;
}

.UserName,
.UserPhone,
.FormCaptcha,
.FormCaptchaEn{
  height: 39px;
  background-color: inherit;
}

.Company{
  width: 100%;
  height: 39px;
  background-color: inherit;
}

.Message{
  resize: none;
  width: 100%;
  height: 210px;
  background-color: #0D0D0D;
  padding: 13px 14px;
  font-family: 'HarmoniaSansProCyr-Regular', sans-serif;
  line-height: 1.43;
  letter-spacing: 0.3px;
  overflow: hidden;
}

.UserName,
.UserPhone,
.Company,
.Message,
.FormCaptcha,
.FormCaptchaEn{
  border-bottom: 1px solid #ffffff;
  font-size: 14px;
  letter-spacing: 0.3px;
  color: #ffffff;
}
/*--modal style--*/
.modal-title{
  margin-bottom: 43px;
}
/*--add .modalClass--*/
.modalClass .Form .UserName,
.modalClass .Form .UserPhone,
.modalClass .Form .Company,
.modalClass .Form .Message,
.modalClass .Form .FormCaptcha,
.modalClass .Form .FormCaptchaEn{
  border-bottom: 1px solid #000000;
  color: #000000;
}
.modalClass .Form{
  width: 100%;
}

.modalClass .Form .Message{
  background: rgba(0, 0, 0, 0.05);
}

.modalClass .Form .FormConsent-label{
  color: #000000;
}

.modalClass .Form .FormConsent-label:before{
  border-color: #000000;
}

.modalClass .Form .FormSubmit .FormCaptchaTest{
  border: 1px solid #000000;
}
/*--end add .modalClass--*/

/*--end modal style--*/

/*--edn ApplicationForm--*/
/*--Footer--*/
.Footer .wrap--wide{
  display: flex;
  justify-content: space-between;
  font-family: 'HarmoniaSansProCyr-Regular', sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  padding-bottom: 32px;
  padding-top: 203px;
}

.Footer.light{
  color: #000;
  background: #fff;
}

.Footer.light .FooterDone{
  color: #000;
}

.FooterCont{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.phone,
.email{
  /*margin: 0 42px;*/
  margin-left: 42px;
}

.FooterDone{
  position: relative;
  font-family: "HarmoniaSansProCyr-Regular", sans-serif;
  width: 210px;
  height: 21px;
  font-size: 16px;
}
.FooterDone svg{
  position: absolute;
  top: 0;
  right: 0;
}
.FooterDone span{
  font-family: 'HarmoniaBureauSemiBold', sans-serif;
}
/*--end Footer--*/
/*--Modal--*/
.modal-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.8);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-wrapper {
  background: #fff;
  width: 996px;
  margin: auto;
  padding: 47px 102px 55px;
  color: #000;
  position: relative;
 }

.modal-wrapper .close{
  width: 18px;
  height: 18px;
  display: inline-block;
  background: inherit;
  cursor: pointer;
  position: absolute;
  top: 30px;
  right: 30px;
}

.modal-title {
  font-size: 40px;
  font-family: 'HarmoniaSansProCyr-SemiBd', sans-serif;
  line-height: 1.2;
  letter-spacing: 0.1px;
  text-align: center;
}
@media (max-width: 1440px) {
  .modal-wrapper {
    width: 940px;
    padding: 47px 80px 55px;
  }
  .modal-title {
    font-size: 32px;
  }
}

/*--Modal end--*/




/*--SchemeClassical--*/
.wrap--scheme{
  padding: 0 120px;
  text-align: left;
}

.SchemeClassical{
  padding-top: 111px;
  text-align: center;
}

.Scheme-link{
  position: relative;
  width: 146px;
  height: 44px;
  font-family: 'HarmoniaSansProCyr-SemiBd', sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;;
  line-height: 2.44;
  letter-spacing: 0.4px;
  padding-left: 92px;
}

.Scheme-link svg{
  display: inline-block;
  position: absolute;
  top: -21px;
  left: 0;
  width: 60px;
  height: 60px;
  background: inherit;
}


.Scheme-link,
.Scheme-link:hover,
.Scheme-link:active,
.Scheme-link:visited,
.Scheme-link:focus{
  color: #3b4df3;
}


.Scheme-head{
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
}

.Scheme-head--dark{
  margin-top: 28px;
}

.Scheme-head__capt{
  display: flex;
  position: relative;
  width: 60%;
  font-family: 'HarmoniaBureauSemiBold', sans-serif;
  font-size: 60px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  padding-left: 91px;
  line-height: 1.2;
  letter-spacing: 0.2px;
  color: #ffffff;
}

.Scheme-head__capt:before{
  position: absolute;
  top: -4px;
  left: -8px;
  content: "\2014";
  display: inline-block;
  font-family: 'HarmoniaSansProCyr-SemiBd', sans-serif;
  font-size: 60px;
  line-height: 1.2;
  margin-right: 25px;
}

.Scheme-head__dsc{
  width: 30%;
  display: flex;
  flex-direction: column;
  padding-left: 110px;
  padding-top: 29px;
}

.Scheme-head__dsc div:first-child{
  margin-bottom: 23px;
}

.head__dsc-layer,
.head__dsc-database{
  position: relative;
  font-family: 'HarmoniaSansProCyr-Regular', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.4px;
  padding-left: 41px;
}

.head__dsc-layer svg,
.head__dsc-database svg{
  display: inline-block;
  position: absolute;
  top: -4px;
  left: 0;
  width: 26px;
  height: 26px;
  background: inherit;
}

.Shema{
  max-width: 1440px;
  width: 100%;
  margin: auto;
}

.ShemaEn{
  max-width: 1440px;
  width: 83.5%;
  margin: auto;
}

.ShemaEnDark{
  max-width: 1440px;
  width: 89%;
  margin: auto;
}

/*--end SchemeClassical--*/
/*--SchemeCapability--*/
.SchemeСapability{
  background-color: #ffffff;
  color: #000000;
}

.Scheme-head--light{
  margin-top: 66px;
}

.Scheme-head__capt--light{
  color: #000000;
}
/*--end SchemeCapability--*/

/*--SchemeClassical--*/
.wrap--scheme{
  padding: 0 120px;
}

.SchemeClassical{
  padding-top: 111px;
}

.Scheme-link{
  position: relative;
  width: 146px;
  height: 44px;
  font-family: 'HarmoniaSansProCyr-SemiBd', sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;;
  line-height: 2.44;
  letter-spacing: 0.4px;
  padding-left: 92px;
}

.Scheme-link,
.Scheme-link:hover,
.Scheme-link:active,
.Scheme-link:visited,
.Scheme-link:focus{
  color: #3b4df3;
}


.Scheme-head{
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
}

.Scheme-head--dark{
  margin-top: 28px;
}

.Scheme-head__capt{
  display: flex;
  position: relative;
  width: 60%;
  font-family: 'HarmoniaBureauSemiBold', sans-serif;
  font-size: 60px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  padding-left: 91px;
  line-height: 1.2;
  letter-spacing: 0.2px;
  color: #ffffff;
}

.Scheme-head__capt:before{
  position: absolute;
  top: -4px;
  left: -8px;
  content: "\2014";
  display: inline-block;
  font-family: 'HarmoniaSansProCyr-SemiBd', sans-serif;
  font-size: 60px;
  line-height: 1.2;
  margin-right: 25px;
}

.Scheme-head__dsc{
  width: 30%;
  display: flex;
  flex-direction: column;
  padding-left: 110px;
  padding-top: 29px;
}

.Scheme-head__dsc div:first-child{
  margin-bottom: 23px;
}

.head__dsc-layer,
.head__dsc-database{
  position: relative;
  font-family: 'HarmoniaSansProCyr-Regular', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.4px;
  padding-left: 41px;
}

.Shema{
  max-width: 1440px;
  width: 100%;
  margin: auto;
}

/*--end SchemeClassical--*/
/*--SchemeCapability--*/
.SchemeCapability{
  background-color: #ffffff;
  color: #000000;
  padding-top: 111px;
  text-align: center;
}

.Scheme-head--light{
  margin-top: 66px;
}

.Scheme-head__capt--light{
  color: #000000;
}
/*--end SchemeCapability--*/





/*--@media 1024px--*/
@media (min-width: 1023px) and (max-width: 1439px) {
  /*--Particles--*/
  .scrollDown{
    right: 50px;
  }

  .particles__content{
    margin-top: -140px;
  }

  .particles__capt{
    margin-top: 155px;
  }

  .btn--particles{
    margin-top: 55px;
  }

  /*--end Particles--*/
  /*--BuroDataTxt--*/
  .BuroDataTxt__desc{
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.5px;
    margin-top: 4px;
  }

  .BuroDataTxt__capt{
    margin-left: 2px;
  }

  .BuroDataTxt--indent{
    margin-bottom: 136px;
    margin-top: 40vh;
  }

  /*--end BuroDataTxt--*/
  /*--ClassicScheme--*/

  .SecondaryCaptionTitle{
    font-size: 48px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: 0.1px;
  }

  .SecondaryCaption {
    top: 77px;
    left: 121px;
  }

  .SecondaryCaptionTitle--ClassicScheme:before {
    top: 3px;
    left: -79px;
  }

  .SecondaryCaptionTitle:before {
    font-size: 48px;
  }

  .ClassicScheme__bg-mono {
    height: 500px;
  }

  .ClassicScheme__bg-mono {
    width: 499px;
  }

  .ClassicScheme__bg-img{
    width: 66%;
    height: 500px;
  }

  .btn--ClassicScheme-indent {
    margin-top: 43px;
    margin-left: 0;
  }

  /*--ClassicScheme--*/
  /*--Approach__ApproachCapt--*/
  .Approach{
    margin-top: 145px;
  }

  .Approach__ApproachCapt{
    font-size: 32px;
    margin-top: 11px;
    line-height: 1.30;
  }

  .ApproachTxt__capt {
    width: 100%;
  }

  .ApproachTxt__list :first-child{
    margin-right: 21px;
  }

  .ApproachTxt__list-block--mnone{
    margin: 8px 0 0 0;
  }

  .BuroDataTransf--indent{
    margin-top: 109px;
  }

  .BuroDataTransf__desc{
    width: calc(66% + 40px);
    border-bottom: 5px solid #3b4df3;
    padding: 0 40px 35px 0;
    margin-right: -40px;
  }

  .ApproachTxt__list-block span{
    display: flex;
    align-items: baseline;
    line-height: 1.33;
  }

  /*--BuroDataTransf--*/

  .BuroDataTransf{
    padding-bottom: 191px;
  }

  .SecondaryCaption{
    top: 95px;
    left: 122px;
  }

  .SecondaryCaptionTitle--Capability:before{
    top: 5px;
    left: -80px;
  }

  .SecondaryCaptionTxt{
    width: 60%;
  }

  .capabil--br {content: ""}

  .SecondaryCaptionTxt{
    width: 70%;
  }

  .SecondaryCaptionTxt--indent{
    margin-top: 19px;
  }

  .btn--DarkBlue-indent{
    margin-top: 39px;
  }

  .btn--DarkBlue-indent {
    margin-top: 39px;
  }

  /*--end BuroDataTransf--*/
  /*--capability--*/

  .CapabilityTxt {
    height: 397px;
  }

  .Spheres__capt{
    font-size: 32px;
    margin-left: 2px;
    margin-bottom: 50px;
  }

  .Spheres {
    padding-top: 71px;
  }

  .Spheres__block {
    justify-content: space-between;
  }

  .Spheres__blockItem:nth-child(1){
    margin-right: 0;
    margin-left: 2px;
  }

  .Spheres__blockItem:nth-child(2){
    margin-right: 0;
  }

  .Spheres__blockItem li {
    line-height: 24px;
    margin-bottom: 24px;
  }

  .Spheres__blockItem{
    width: 32%;
  }

  /*--end capability--*/
  /*--Advantages--*/
  .Advantages > .wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .Advantages{
    padding-top: 104px;
    padding-bottom: 69px;
  }

  .AdvantagesIco{
    margin-bottom: 20px;
  }

  .Advantages__title {
    width: 49%;
    font-size: 48px;
    line-height: 1.17;
    letter-spacing: 0.1px;
    margin: 0 0 10px 0;
  }

  .AdvantagesCapt{
    width: 100%;
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: 0.1px;
    margin-bottom: 26px;
  }
  .Advantages__block {
    width: 463px;
    margin: 0 0 74px 0;
    padding-left: 2px;
  }
  .Advantages__block:nth-child(odd) {
    margin-right: 10px;
  }

  /*--end Advantages--*/
  /*--SliderProj--*/
  .SliderCapt {
    font-size: 32px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
  }

  .SliderTitle{
    padding-top: 117px;
  }

  .SliderCont{
    font-size: 80px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.2px;
  }

  .Slider--indent {
    margin: 72px 0 23px 2px;
  }

  .SliderProjLink{
    margin-left: 0;
  }

  .Platform__capt{
    font-size: 24px;
    font-weight: normal;
    line-height: 1.33;
    letter-spacing: 0.1px;
  }

  .SlderBottomBlock{
    flex-direction: column;
    margin-left: 2px;
  }

  .SlderBottomBlock > * {
    width: 100%;
  }

  .PlatformCombines,
  .PlatformUnite{
    display: flex;
  }

  .Platform__list{
    flex-direction: row;
    width: 60%;
    justify-content: space-between;
  }

  .PlatformCombines{
    margin-bottom: 6%;
  }

  .PlatformCombines .Platform__list {
    padding-left: 0;
  }

  .Form__TopString .FormGroup{
    width: 49%;
  }

  .Platform__capt{
    width: 30%;
    margin-right: 45px;
  }

  .SliderProjLink {
    margin-bottom: 55px;
  }


  .Platform__list--left span{
    width: 48%;
  }

  .Platform__list--unite span:nth-child(odd){
    width: 48%;
    margin-right: 0px;
    margin-left: 0;
  }

  .Platform__list--unite span:nth-child(even){
    width: 48%;
  }

  .Platform__list--unite span{
    width: 48%;
  }

  /*--end SliderProj--*/
  /*--ApplicationForm--*/
  .ApplicationFormCapt{
     font-size: 32px;
     line-height: 1.38;
     letter-spacing: 0.1px;
   }
  /*--form 1024px--*/
  .Form {
    width: 620px;
  }

  .FormCaptchaTest {
    width: 140px;
    margin-right: 18px;
  }

  .FormCaptcha {
    width: 140px;
    margin-right: 10px;
  }

  .FormCaptchaEn{
    margin-right: 10px;
    text-overflow: ellipsis;
  }

  .FormSubmit{
    margin: 28px 0 19px 0;
  }

  .FormConsent{
    position: relative;
    padding-left: 32px;
  }

  .FormConsent-label{
    padding-left: 0;
    line-height: 24px;
  }

  .FormConsent-label:before{
    position: absolute;
    top: 0;
    left: -1px;
    margin: 0;
  }

  .modalClass .FormCaptchaTest{
    width: 181px;
    margin-right: 19px;
  }

  .modalClass .FormCaptcha{
    width: 182px;
    margin-right: 177px;
  }

  .modalClass .modal-title{
    margin-bottom: 42px;
  }
  /*--end form 1024px--*/
  /*--end ApplicationForm--*/
  /*--footer--*/
  .Footer {
    padding-top: 150px;
  }
  .Footer .wrap--wide{
    padding-top: 0;
  }
  /*--end footer--*/

  /*--SchemeClassical 1024px--*/
  .wrap--scheme{
    padding: 0 40px;
  }

  .Scheme-head__capt{
    font-size: 48px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: 0.1px;
    padding-left: 81px;
  }

  .Scheme-head__capt:before{
    top: -1px;
    left: 2px;
    content: "\2014";
    font-size: 48px;
    line-height: 1.17;
    letter-spacing: 0.1px;
    margin-right: 25px;
  }

  .Scheme-head__dsc {
    padding-left: 42px;
    padding-top: 20px;
  }

  .Scheme-link {
    padding-left: 96px;
  }

  .Scheme-link svg{
    left: 3px;
  }

  .Shema {
    max-width: 1024px;
    width: 100%;
  }

  .Scheme-head--dark{
    margin-top: 44px;
  }

  /*--end SchemeClassical 1024px--*/
  /*--SchemeCapability 1024px--*/
  .Scheme-head--light{
    margin-top: 45px;
  }

  /*--end SchemeCapability 1024px--*/

}

/*--end @media 1024px--*/
/*--@media 768px--*/
@media (max-width: 1023px){

  /*--BuroDataTxt--*/
  .BuroDataTxt{
    flex-direction: column;
  }

  .BuroDataTxt__capt,
  .BuroDataTxt__desc{
    width: 100%;
  }

  .BuroDataTxt--indent{
    margin-bottom: 89px;
    margin-top: 53px;
  }

  .BuroDataTxt__capt{
    opacity: 0.5;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.8px;
    margin-bottom: 26px;
  }

  .BuroDataTxt__desc{
    width: 100%;
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6;
    letter-spacing: 0.4px;
  }

  /*--end BuroDataTxt--*/
  /*--ClassicScheme--*/
  .ClassicScheme{
    flex-direction: column;
  }

  .ClassicScheme__bg-mono{
    width: 100%;
    height: 298px;
  }

  .ClassicScheme__bg-img{
    width: 100%;
    object-fit: contain;
    height: auto;
    padding: 0 24px;
    background: #111;
  }

  .SecondaryCaption{
    left: 86px;
    top: 56px;
  }

  .SecondaryCaptionTitle,
  .SecondaryCaptionTitle:before{
    font-size: 38px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.11;
    letter-spacing: 0.1px;
  }

  .SecondaryCaptionTitle--ClassicScheme:before,
  .SecondaryCaptionTitle--Capability:before{
    top: -2px;
    left: -62px;
  }

  .btn--ClassicScheme-indent {
    margin-top: 28px;
    margin-left: 0px;
  }

  /*--end ClassicScheme--*/
  /*--Approach--*/
  .Approach{
    flex-direction: column;
    margin-top: 98px;
  }

  .Approach__ApproachCapt{
    width: 100%;
    font-size: 28px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.21;
    letter-spacing: 0.1px;
    margin-top: 0;
  }

  .ApproachTxt__capt{
    width: 80%;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.4px;
  }

  .ApproachTxt__list{
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.1px;
  }

  .ApproachTxt__capt{
    margin-top: 14px;
    margin-bottom: 21px;
  }

  .ApproachTxt__list-block span{
    margin-bottom: 5px;
  }

  .ApproachTxt__list > * {
    width: calc(50% - 10px);
  }

  /*--end Approach--*/
  /*--BuroDataTransf--*/
  .BuroDataTransf{
    padding-bottom: 132px;
  }

  .BuroDataTransf--indent {
    margin-top: 41px;
  }

  .BuroDataTransf__desc{
    width: calc(100% + 24px);
    border-bottom: 5px solid #3b4df3;
    padding: 0 24px 25px 0;
    margin-right: -24px;
  }

  /*--Capability--*/

  .capabil--br{
    display: none;
  }

  .CapabilityTxt{
    height: auto;
  }

  .SecondaryCaption.SecondaryCaption--CapabilityIndent{
    position: relative;
    top: auto;
    left: auto;
    padding: 78px 24px 50px 64px;
    width: 100%;
  }

  .SecondaryCaptionTxt{
    font-size: 16px;
    line-height: 24px;
  }

  .SecondaryCaptionTxt--indent {
    margin-top: 13px;
  }

  .btn--DarkBlue-indent {
    margin-top: 32px;
  }

  .CapabilityPlatformWhite{
    object-fit: contain;
    height: auto;
  }

  .SecondaryCaptionTitle--Capability{
    position: relative;
  }

  .Spheres{
    padding-top: 46px;
  }

  .Spheres__capt{
    font-size: 28px;
    width: auto;
    margin: 30px 0 20px;
  }

  .Spheres__block{
    width: 100%;
    justify-content: space-between;
  }

  .Spheres__blockItem:nth-child(n){
    margin-right: 0;
    width: 33%;
    font-size: 16px;
  }

  .Spheres__blockItem li{
    line-height: 26px;
    padding: 7px 0;
  }

  /*--end Capability--*/

  /*--end BuroDataTransf--*/

  /*--Advantages--*/
  .Advantages{
    padding: 0 0 65px;
  }
  .Advantages__title{
    font-size: 38px;
    width: auto;
    margin: 78px 0 18px;
  }

  .Advantages__block {
    width: 100%;
    padding-left: 125px;
    position: relative;
    margin: 27px 0;
  }

  .AdvantagesIco {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
  }

  .AdvantagesCapt{
    width: auto;
    font-size: 20px;
    line-height: 1;
    margin-bottom: 21px;
  }
  .AdvantagesCapt br{
    display: none;
  }
  .AdvantagesDsc__Cont{
    width: 100%;
  }
  /*--end Advantages--*/


  /*--Slider--*/
  .SliderTitle{
    padding-top: 78px;
  }
  .SliderCapt{
    font-size: 28px;
    line-height: 34px;
  }
  .Slider--indent{
    margin: 68px 0 32px;
  }
  .SliderCont{
    font-size: 60px;
    line-height: 60px;
    height: 60px;
  }
  .SliderProjLink{
    font-size: 16px;
    margin: 0 0 3px;
  }
  .Platform__capt{
    margin: 36px 0 28px;
    width: 100%;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0;
  }
  .Platform__list{
    font-size: 16px;
    line-height: 18px;
  }
  .Platform__capt br{
    display: none;
  }
  .SlderBottomBlock{
    flex-wrap: wrap;
  }
  .SlderBottomBlock > *{
    width: 100%;
  }
  .Platform__list{
    flex-direction: row;
    margin: 0 -12px;
  }
  .Platform__list > *{
    width: calc(50% - 24px);
    margin: 0 12px;
  }
  .PlatformCombines .Platform__list{
    padding-left: 0;
  }
  .PlatformUnite{
    margin-left: 0;
    margin-top: -10px;
  }
  .SliderBtn__prev,
  .SliderBtn__next{
    top: -32px
  }
  .SliderProj{
    padding-bottom: 88px;
  }

  .Platform__list--unite span:nth-child(odd){
    margin-right: 40px;
  }

  /*--end Slider--*/
  /*--ApplicationForm--*/
  .ApplicationForm{
    display: block;
  }
  .ApplicationFormCapt{
    width: 100%;
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 35px;
  }
  .Form {
    width: 100%;
  }

  .Form__TopString .FormGroup {
    width: calc(50% - 24px);
  }
  .FormConsent {
    margin-right: 100px;
    margin-left: 0;
    padding-left: 33px;
    position: relative;
  }
  .FormConsent-label:before {
    margin-left: 0;
    margin-top: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
  .FormConsent-label{
    padding-left: 0;
  }
  /*--end ApplicationForm--*/

  .Footer .wrap--wide{
    padding-top: 108px;
  }

  /*--Modal--*/
  .modal-container:not(.success-modal) {
    justify-content: flex-end;
    align-items: flex-start;
  }
  .modal-wrapper{
    min-width: 396px;
    width: 50%;
    padding: 40px 24px 130px;
    margin: 0;
    height: 100%;
    overflow-y: scroll;
  }

  .success-modal .modal-wrapper{
    height: 420px;
    width: calc(100% - 48px);
    overflow-y: hidden;
    padding: 80px 100px;
  }

  .modal-title{
    font-size: 20px;
    line-height: 28px;
    text-align: left;
    margin: 46px 0 25px;
  }

  .modal-form{
    position: relative;
  }

  .modal-form .Form__TopString{
    display: block;
  }
  .modal-form .Form__TopString .FormGroup{
    width: 100%;
    margin-bottom: 30px;
  }
  .modal-form .Form__TopString{
    margin: 0;
  }
  .modal-form .FormConsent{
    margin-right: 0;
  }
  .modal-form .FormConsent-label{
    font-size: 12px;
    line-height: 20px;
  }
  .modal-form .FormSubmit span{
    position: absolute;
    left: 0;
    top: 510px;
  }
  .modal-form .btn--form{
    position: absolute;
    width: 100%;
    top: calc(100% + 34px);
    line-height: 40px;
    height: 40px;
  }

  .form-ok.modal-form{
    margin-top: 25vh;
  }
  /*--end Modal--*/


  /*--Scheme--*/
  .wrap--scheme{
    padding: 0 24px;
  }
  .SchemeClassical{
    padding-top: 95px;
  }
  .Scheme-link{
    font-size: 16px;
    line-height: 40px;
    padding-left: 60px;
    display: block;
    width: 100%;
  }
  .Scheme-link svg{
    width: 40px;
    height: 40px;
    background-size: cover;
    top: 0;
  }
  .Scheme-head{
    margin-top: 50px;
    display: block;
  }
  .Scheme-head__capt{
    width: 100%;
    font-size: 38px;
    line-height: 42px;
    padding-left: 62px;
  }
  .Scheme-head__capt:before{
    font-size: 38px;
    line-height: 42px;
    top:0;
    left: 0;
  }
  .Scheme-head__dsc {
    width: 100%;
    flex-direction: row;
    padding: 35px 0 17px 62px;
  }
  .head__dsc-layer, .head__dsc-database{
    padding-left: 38px;
  }
  .head__dsc-database{
    margin-left: 40px;
  }

  .Scheme-head--light{
    margin-top: 35px;
  }
  .Scheme-head__capt--light{
    width: 400px;
  }
  /*--end Scheme--*/

  .phone,
  .email{
    margin-left: 20px;
  }

  .FormCaptchaEn{
    text-overflow: ellipsis;
  }
}

@media (max-width: 846px) {
  .FooterCont {
    width: 70%;
  }

  .email{
    margin-left: 0;
  }
}

@media (max-width: 842px) {

  .email{
    margin-top: 20px;
  }
}

/*--end @media 768px--*/

@media (max-width: 767px) {

  .btn{
    font-size: 14px;
  }

  .BuroDataTxt__capt{
    margin: 60px 0 17px;
  }
  .BuroDataTxt__desc{
    font-size: 18px;
    line-height: 28px;
  }

  .BuroDataTxt--indent{
    margin: 60px 0 49px;
  }

  .SecondaryCaption{
    left: 24px;
    top: 37px;
    width: calc(100% - 48px);
    letter-spacing: 0;
  }

  .SecondaryCaptionTitle{
    width: 100%;
    font-size: 24px;
    line-height: 28px;
  }
  .SecondaryCaptionTitle:before{
    display: none;
  }

  .btn--ClassicScheme-indent{
    margin-top: 32px;
    width: 100%;
    font-size: 14px;
    line-height: 35px;
  }

  .ClassicScheme__bg-mono{
    height: 205px;
  }

  .Approach{
    margin-top: 54px;
  }

  .Approach__ApproachCapt{
    font-size: 20px;
    line-height: 28px;
  }
  .ApproachTxt__capt{
    width: 100%;
    margin-top: 17px;
    margin-bottom: 26px;
  }

  .ApproachTxt__list{
    display: block;
    height: auto;
  }

  .ApproachTxt__list > *{
    width: 100%;
    padding-left: 22px;
    line-height: 24px;
  }

  .ApproachTxt__list-block span {
    margin: 0 0 17px;
    position: relative;
  }

  .ApproachTxt__list-block span:before{
    position: absolute;
    top: -4px;
    left: -22px;
    font-size: 16px;
  }

  .BuroDataTxt__capt {
    margin: 0 0 17px;
  }

  .BuroDataTransf{
    padding-bottom: 50px;
  }

  .BuroDataTransf--indent {
    margin-top: 36px;
  }

  .BuroDataTransf__desc{
    border: none;
    padding-bottom: 0;
  }


  .SecondaryCaption.SecondaryCaption--CapabilityIndent{
    padding: 58px 0 0;
  }

  .SecondaryCaptionTxt{
    font-size: 16px;
  }
  .SecondaryCaptionTxt--indent{
    margin-top: 18px;
  }
  .btn--DarkBlue{
    line-height: 40px;
    width: 100%;
  }
  .btn--DarkBlue-indent{
    margin-top: 30px;
  }
  .CapabilityPlatformWhite{
    margin-top: 40px;
  }

  .Spheres{
    padding-top: 33px;
  }
  .Spheres__capt{
    font-size: 20px;
    margin: 0 0 16px;
  }
  .Spheres__blockItem li {
     line-height: 20px;
     padding: 8px 0;
   }
  .Spheres__block{
    display: block;
  }
  .Spheres__blockItem:nth-child(n){
    width: 100%;
  }


  .Advantages__title {
    font-size: 24px;
    margin: 40px 0 16px;
  }
  .AdvantagesIco{
    width: 60px;
    height: 60px;
    position: relative;
    left: 0;
    margin-bottom: 12px;
  }

  .AdvantagesCapt{
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0;
    margin-bottom: 14px;
  }
  .Advantages__block{
    margin: 16px 0;
    padding-left: 0;
  }
  .Advantages__block:nth-child(odd){
    margin-right: 0;
  }
  .AdvantagesDsc__Cont{
    margin-left: 21px;
  }
  .Advantages {
    padding: 0 0 56px;
  }


  .SliderTitle{
    padding-top: 40px;
    padding-bottom: 25px;
  }
  .SliderCapt{
    font-size: 20px;
    line-height: 1;
  }
  .Slider--indent{
    margin: 20px 0 12px;
    position: relative;
  }
  .SliderCont{
    width: 100%;
    font-size: 28px;
    line-height: 1;
    height: 28px;
    letter-spacing: 0.1px;
  }
  .SliderProjLink{
    margin: 20px 0 0;
  }
  .SliderProjLink:after{
    top: 0;
  }
  .Platform__capt{
    margin: 29px 0 22px;
    font-size: 18px;
    line-height: 24px;
  }
  .Platform__list{
    flex-direction: column;
    font-size: 16px;
    line-height: 20px;
  }
  .Platform__list > *,
  .Platform__list.Platform__list--unite > *{
    /*width: calc(100% - 24px);*/
    /*margin-bottom: 18px;*/
  }
  .Platform__list span,
  .Platform__list.Platform__list--unite span{
    margin-bottom: 18px;
  }
  .accordeon-item {
    margin: 0 -24px;
    padding: 0 24px;
    border-bottom: 1px solid rgba(255,255,255,0.2);
  }
  .accordeon-item.active{
    padding-bottom: 24px;
  }
  .accordeon-item .SliderProjLink,
  .accordeon-item .SlderBottomBlock{
    display: none;
  }
  .accordeon-item .Slider--indent:after {
    content: "+";
    position: absolute;
    right: 0;
    top: 0;
    font-size: 32px;
    line-height: 24px;
  }
  .accordeon-item.active .SliderProjLink{
    display: inline-block;
  }
  .accordeon-item.active .SlderBottomBlock{
    display: flex;
  }
  .accordeon-item.active .Slider--indent:after {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }

  .SliderProj{
    padding-bottom: 53px;
  }

  .Platform__list--left span{
    max-width: 272px;
    width: 100%;
  }

  .Platform__list--unite span:nth-child(odd){
    margin-right: 0;
    margin-left: 0;

  }

  .Platform__list--unite span:nth-child(odd),
  .Platform__list--unite span:nth-child(even){
    width: 90%;
  }

  .ApplicationFormCapt {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 27px;
  }

  form.Form{
    position: relative;
    padding-bottom: 70px;
  }

  .success-modal .modal-wrapper{
    height: auto;
    width: calc(100% - 20px);
    overflow-y: hidden;
    padding: 60px 14px 50px;
  }

  .modal-form form.Form{
    position: relative;
    padding-bottom: 0;
  }

  .Form__TopString{
    display: block;
    margin-bottom: 0;
  }

  .Form__TopString .FormGroup,
  .Form__CompString{
    width: 100%;
    margin-bottom: 30px;
  }

  .FormSubmit{
    margin: 26px 0;
  }

  .btn--form {
    position: absolute;
    width: 100%;
    bottom: 0;
    line-height: 40px;
  }

  .FormCaptcha{
    width: auto;
    min-width: 124px;
  }

  .FormCaptchaEn{
    width: auto;
    min-width: 124px;
    text-overflow: ellipsis;
  }

  .FormConsent{
    margin-right: 0;
  }

  .FormConsent-label {
    font-size: 12px;
    line-height: 20px;
  }

  .FooterCont {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
  .copyright {
    width: 100%;
    order: 1;
    text-align: center;
    opacity: 0.5;
    margin-top: 65px;
  }
  .phone {
    margin: 0 42px 0 0;
  }
  .Footer{
    position: relative;
  }
  .FooterDone {
    position: absolute;
    bottom: 74px;
    left: 50%;
    margin-left: -105px;
  }


  .modal-wrapper {
    width: 100%;
    min-width: 320px;
    padding: 21px 24px 100px;
  }

  .modal-wrapper .close{
    top: 18px;
    right: 24px;
  }

  .modal-title{
    margin: 39px 0 25px;
  }
  .menu .company {
    display: block;
    font-family: "HarmoniaSansProCyr-SemiBd", sans-serif;
  }

  .modal-form .FormSubmit span{
    top: 490px;
  }

  .SchemeCapability,
  .SchemeClassical{
    padding-top: 40px;
    min-height: calc(100vh - 191px);
  }
  .Scheme-link{
    display: none;
  }
  .Scheme-head{
    margin-top: 28px;
  }
  .Scheme-head__capt{
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.1px;
    padding: 0;
    width: 272px;
  }
  .Scheme-head__capt:before{
    display: none;
  }
  .Footer .wrap--wide{
    padding-top: 60px;
  }

  .Scheme-head__dsc{
    flex-direction: column;
    padding: 36px 0 30px;
  }

  .head__dsc-database{
    margin-left: 0;
  }

  ::-webkit-input-placeholder { font-size: 14px;}
  :-moz-placeholder { font-size: 14px;} /* FF 4-18 */
  ::-moz-placeholder { font-size: 14px;} /* FF 19+ */
  :-ms-input-placeholder { font-size: 14px;} /* IE 10+ */
}

@media (max-width: 425px) {
  .phone{
    margin: 0;
    width: 35%;
  }

}

@media (max-width: 395px) {
  .phone{
    margin: 0;
    width: 50%;
    text-align: center;
  }

}

@media (max-width: 499px) {
  .FormSubmit span {
    position: absolute;
  }

  .FormSubmit span {
    position: absolute;
    left: 0;
    top: 490px;
    width: 100%;
  }

  /*--slider hidden last title--*/
  .Slider :not(:first-child){
    display: none;
  }
  /*--end slider hidden last title--*/
}


@media (min-width: 1440px) {
  .slick-active .Platform__list{
    margin-top: 100px;
    opacity: 0;
    -webkit-animation: slider-fade 1s forwards;
            animation: slider-fade 1s forwards;
  }

}


@media (min-width: 1024px) {
  .main.transition .particles{
    -webkit-animation: first-slide-move-up 1s forwards;
            animation: first-slide-move-up 1s forwards;
  }

  @-webkit-keyframes first-slide-move-up {
    from {
      opacity: 1;
      height: 100vh;
    }
    50%{
      opacity: 0;
      height: 100vh;
    }
    to {
      opacity: 0;
      height: 0;
    }
  }

  @keyframes first-slide-move-up {
    from {
      opacity: 1;
      height: 100vh;
    }
    50%{
      opacity: 0;
      height: 100vh;
    }
    to {
      opacity: 0;
      height: 0;
    }
  }

  .main.transition .scrollDown__line-with-arrow{
    -webkit-animation: scroll-line-down 1s forwards;
            animation: scroll-line-down 1s forwards;
  }

  .main.transition .scrollDown__txt{
    -webkit-animation: to-transparent 1s forwards;
            animation: to-transparent 1s forwards;
  }

  .main.transition .scrollDown svg{
    -webkit-animation: to-transparent 0s 1s forwards;
            animation: to-transparent 0s 1s forwards;
  }

  .main.transition .BuroDataTxt__desc{
    opacity: 0;
    -webkit-animation: to-visible 1500ms 600ms forwards;
            animation: to-visible 1500ms 600ms forwards;
  }

  @-webkit-keyframes scroll-line-down {
    from {
      width: 346px;
      margin-right: 0;
    }
    to {
      width: 0;
      margin-right: 346px;
      overflow: hidden;
    }
  }

  @keyframes scroll-line-down {
    from {
      width: 346px;
      margin-right: 0;
    }
    to {
      width: 0;
      margin-right: 346px;
      overflow: hidden;
    }
  }


  @-webkit-keyframes to-transparent {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }


  @keyframes to-transparent {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }



  @-webkit-keyframes to-visible {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }



  @keyframes to-visible {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

@media (min-width: 768px) {
  .slick-active .SliderCont{
    margin-left: 100px;
    opacity: 0;
    -webkit-animation: slider-fade-left 0.5s forwards;
            animation: slider-fade-left 0.5s forwards;
  }

  /*.slick-active .SliderCont:after{*/
    /*content: attr(data-next);*/
    /*margin-left: 50px;*/
    /*position: absolute;*/
    /*opacity: 0.3;*/
  /*}*/
}

@-webkit-keyframes slider-fade {
  from{
    margin-top: 100px;
    opacity: 0;
  }
  to{
    margin-top: 0;
    opacity: 1;
  }
}

@keyframes slider-fade {
  from{
    margin-top: 100px;
    opacity: 0;
  }
  to{
    margin-top: 0;
    opacity: 1;
  }
}

@-webkit-keyframes slider-fade-left {
  from{
    margin-left: 100px;
    opacity: 0;
  }
  to{
    margin-left: 0;
    opacity: 1;
  }
}

@keyframes slider-fade-left {
  from{
    margin-left: 100px;
    opacity: 0;
  }
  to{
    margin-left: 0;
    opacity: 1;
  }
}

.form-ok{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  line-height: 42px;
}
.form-ok svg{
  width: 146px;
  height: 146px;
}

.form-ok .title{
  margin: 37px 0 7px;
  font-size: 40px;
  line-height: 48px;
  font-family: 'HarmoniaBureauSemiBold', sans-serif;
}
.form-ok .btn{
  margin-top: 27px;
  width: 220px;
}

@media (max-width: 1439px) {
  /*.form-ok img {*/
    /*width: 120px;*/
    /*height: 120px;*/
  /*}  */
  .form-ok svg {
    width: 120px;
    height: 120px;
  }
  .form-ok .title{
    margin: 27px 0 7px;
    font-size: 32px;
    line-height: 42px;
  }
}

@media (max-width: 1023px) {
  .form-ok{
    font-size: 16px;
    text-align: center;
    line-height: 24px;
  }
  /*.form-ok img {*/
    /*width: 100px;*/
    /*height: 100px;*/
  /*}  */
  .form-ok svg {
    width: 100px;
    height: 100px;
  }
  .form-ok .title{
    margin: 14px 0 5px;
    font-size: 20px;
    line-height: 42px;
  }

  .form-ok .btn{
    height: 40px;
    line-height: 40px;
    margin-top: 35px;
  }

  .modal-form.form-ok .btn{
    width: 348px;
    position: fixed;
    bottom: 34px;
    right: 24px;
  }
}

@media (max-width: 767px) {
  /*.form-ok img {*/
    /*width: 80px;*/
    /*height: 80px;*/
  /*}*/
  .form-ok svg {
    width: 80px;
    height: 80px;
  }
  .form-ok .title{
    margin: 21px 0 12px;
    font-size: 20px;
    line-height: 28px;
  }

  .form-ok .btn{
    height: 40px;
    line-height: 40px;
    margin-top: 35px;
    width: 100%;
  }

  .modal-form.form-ok .btn{
    position: relative;
    margin-top: 61px;
    right: auto;
    bottom: auto;
  }

  .form-ok.modal-form {
    margin-top: 48px;
  }
}


/* <--------- begin SiemensCaseEn*/

.project-en .wrap--in{
    max-width: 1440px;
    width: 100%;
    padding: 0 220px;
    margin: 0 auto;
    box-sizing: border-box;
    position: relative;
}

.project-en-bg{
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
}

.project-en{
  background-color: #ffffff;
  padding-top: 163px;
  color: #000000;
}

.project-en .Scheme-link{
  margin-left: 9px;
}

.project-en .Scheme-head__capt {
  padding-left: 99px;
}

.project-en .Scheme-head__capt:before {
  left: 0;
}

.project-en-bg{
  width: 100%;
  height: 600px;
}

.siemens-case-bg{
  background: url(/static/media/SiemensCaseEn_bg.1a8f693e.jpg) no-repeat;
  background-size: cover;
}

.scr01-txt__dsc{
  display: flex;
}

.scr01-txt__dsc--block{
  display: flex;
  flex-direction: column;
}

.scr01-txt__dsc--client-name {
  font-size: 12px;
  color: #757575;
  margin-bottom: 6px;
}

.scr01-txt__dsc--brand {
  font-family: 'HarmoniaSansProCyr-Regular', sans-serif;
  font-size: 15px;
  line-height: 1.4;
  letter-spacing: normal;
}

.dsc--brand__dot{
  display: inline-block;
  width: 2px;
  height: 2px;
  background-color: #000000;
  margin: 0 13px;
  vertical-align: middle;
}

.scr01-txt__dsc--block:nth-child(2){
  margin-left: 80px;
}

.project-en-bg{
  margin-top: 47px;
  margin-bottom: 70px;
}

.en-first-scr-txt > div:nth-child(2){
  margin-top: 83px;
}
.txt-capt {
  /*font-family: HarmoniaBureauSansProCyr-SemiBd;*/
  font-family: 'HarmoniaBureauSemiBold', sans-serif;
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: normal;
  margin-bottom: 28px;
}

.txt-par {
  font-family: 'HarmoniaSansProCyr-Regular', sans-serif;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0;
  color: rgba(0, 0, 0, 0.87);
}

.case-txt__par{
  margin-bottom: 32px;
}

.case-txt__par:last-child{
  margin-bottom: 0;
}

.siemens-img-wrap{
  text-align: center;
  background-color: #EDEFF1;
}

.laptop-img{
  display: block;
  max-width: 1265px;
  width: 100%;
  margin: 0 auto;
  padding: 88px 10px;
}

.customer-area__txt{
    width: 95%;
    padding-top: 115px;
    padding-bottom: 103px;
}

.txt-par:not(:last-child){
  margin-bottom: 26px;
}

.client-img320{
  display: none;
}

.client-img{
  display: block;
  max-width: 1278px;
  width: 100%;
  margin: 0 auto;
  padding-top: 90px;
}

.phone-img,
.security-img01{
  display: block;
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;
  padding: 75px 0;
}

.en-first-scr-txt{
  padding: 0 100px;
  margin-bottom: 79px;
}

.phones__txt:not(:last-child){
  margin-bottom: 31px;
}

.case-en-info.info-txt-ul .wrap--in{
    padding-top: 117px;
    padding-bottom: 115px;
}

.case-en-info.info-txt-ul .txt-par{
    width: 96%;
    margin-bottom: 27px;
}

.case-en-info.info-txt-ul .wrap--in :last-child.phones__txt :nth-child(2){
    margin-bottom: 32px;
}

.case-en-info.info-txt-ul .wrap--in :last-child.phones__txt :nth-child(3),
.case-en-info.info-txt-ul .wrap--in :last-child.phones__txt :nth-child(4),
.case-en-info.info-txt-ul .wrap--in :last-child.phones__txt :nth-child(5){
    display: inline-block;
    position: relative;
    margin-left: 37px;
}

.case-en-info.info-txt-ul .wrap--in :last-child.phones__txt :nth-child(3),
.case-en-info.info-txt-ul .wrap--in :last-child.phones__txt :nth-child(4){
    margin-bottom: 5px;
}

.case-en-info.info-txt-ul .wrap--in :last-child.phones__txt :nth-child(3):before,
.case-en-info.info-txt-ul .wrap--in :last-child.phones__txt :nth-child(4):before,
.case-en-info.info-txt-ul .wrap--in :last-child.phones__txt :nth-child(5):before{
    content: '';
    position: absolute;
    top: 11px;
    left: -24px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #000000;
}

.other-cases__capt {
    /*font-family: HarmoniaBureauSansProCyr-SemiBd;*/
    font-family: 'HarmoniaBureauSemiBold', sans-serif;
    font-size: 30px;
    line-height: 1.2;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
}

.img-blocks{
    width: 588px;
    height: 392px;
}

.img-blocks.raiffCase span{
  display: inline-block;
  color: #FFFFFF;
}

.img-blocks a{
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: 29px 32px;
  transition: 0.5s ease-in-out;
}

.img-blocks a:hover {
  transition: 0.5s ease-in-out;
  background: rgba(28,28,28,0.8);
}

.other-cases__img-blocks{
  display: flex;
  justify-content: space-between;
}

.img-blocks span {
  /*font-family: HarmoniaBureauSansProCyr-SemiBd;*/
  font-family: 'HarmoniaBureauSemiBold', sans-serif;
  font-size: 30px;
  line-height: 1.2;
  letter-spacing: normal;
  color: #ffffff;
}

.other-cases__img-blocks{
  margin-top: 54px;
  margin-bottom: -100px;
}

.siemens-case-en-header{
  padding: 30px 0;
  margin: 0 -20px;
}

.header.siemens-case-en-header .menu{
  padding-right: 265px;
}

.project-en ~ .Footer .wrap--wide {
  padding: 186px 50px 27px;
}

.project-en ~ .Footer .wrap--wide .FooterCont{
    margin-left: 25px;
}

.raiffCase,
.motiveCase,
.siemensCase{
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
}

.raiffCase{
  background-image: url(/static/media/raiffeisen.049e6562.png);
}

.motiveCase{
  background-image: url(/static/media/motiv.43f3117e.jpg);
}

.siemensCase{
  background-image: url(/static/media/siemens_other_bg.f74dae2b.jpg);
}

.project-en a{
  transition: 0.25s ease-in-out;
}

.scr01-txt__dsc--brand-block a:hover{
  transition: 0.25s ease-in-out;
  opacity: 0.67;
}

.other-cases__img-blocks div:first-child{
  margin-right: 15px;
}

@media (max-width: 1280px) {
  .project-en .wrap--indent {
    padding: 0 40px;
  }

  .project-en-bg{
    margin-bottom: 80px;
  }

  .en-first-scr-txt > div:nth-child(2) {
    margin-top: 80px;
  }

  .en-first-scr-txt {
    margin-bottom: 130px;
  }

  .laptop-img {
    max-width: 1125px;
    width: 100%;
    padding: 60px 10px;
  }

  .customer-area__txt{
    padding-top: 130px;
  }

  .txt-capt,
  .txt-par:not(:last-child){
    margin-bottom: 30px;
  }

  .client-img {
    max-width: 1136px;
    width: 100%;
  }

  .phone-img,
  .security-img01{
    max-width: 1138px;
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .project-en .wrap--indent {
    padding: 0 39px;
  }

  .siemens-case-en-header{
    margin: 0;
  }

  .project-en-bg {
    width: 100%;
    height: 482px;
    margin-bottom: 60px;
  }

  .en-first-scr-txt > div:nth-child(2){
    margin-top: 60px;
  }

  .en-first-scr-txt{
    padding: 0 88px;
    margin-bottom: 110px;
  }

  .laptop-img {
    max-width: 897px;
    width: 100%;
    padding: 50px 10px;
  }

  .customer-area__txt{
    padding-top: 110px;
    padding-bottom: 110px;
  }

  .client-img{
    padding-top: 70px;
  }

  .project-en .wrap--in{
    padding: 0 140px;
  }

  .phone-img,
  .security-img01{
    padding: 70px 0;
  }

  .client-img,
  .phone-img,
  .security-img01{
    max-width: 880px;
    width: 100%;
  }

  .other-cases__img-blocks div {
    width: 450px;
    height: 306px;
  }

  .project-en ~ .Footer{
    padding: 0;
  }

  .case-en-info.info-txt-ul .wrap--in {
    padding-top: 110px;
    padding-bottom: 110px;
  }

  .other-cases__img-blocks {
    margin-top: 60px;
  }

  .other-cases__capt{
    margin-left: 19px;
  }
}

@media (max-width: 768px) {

  .project-en .Scheme-head__capt{
    padding-left: 60px;
  }

  .project-en {
    padding-top: 80px;
  }

  .siemens-case-en-header {
     margin: 0;
  }

  .project-en .wrap--indent {
    padding: 0 20px;
  }

  .other-cases__img-blocks div:first-child {
    margin-right: 0;
  }

  .project-en-bg {
    width: 100%;
    height: 380px;
    margin-top: 30px;
    margin-bottom: 60px;
  }

  .project-en .Scheme-head {
    margin-top: 20px;
  }

  .en-first-scr-txt > div:nth-child(2) {
    margin-top: 60px;
  }

  .en-first-scr-txt {
    margin-bottom: 60px;
    padding: 0 20px;
  }

  .customer-area__txt{
    padding-top: 90px;
    padding-bottom: 90px;
  }

  .laptop-img {
    max-width: 688px;
    width: 100%;
  }

  .project-en .wrap--in {
    padding: 0 40px;
  }

  .phone-img,
  .security-img01{
    padding: 50px 0;
  }

  .client-img,
  .phone-img,
  .security-img01{
    max-width: 688px;
    width: 100%;
  }

  .client-img{
    padding-top: 50px;
  }

  .case-en-info.info-txt-ul .wrap--in {
    padding: 50px 39px 20px 39px;
  }

  .other-cases__img-blocks{
    display: flex;
    flex-direction: column;
  }

  .other-cases__img-blocks div{
    width: 100%;
    height: 458px;
  }

  .other-cases__capt,
  .other-cases__img-blocks{
    padding: 0 20px;
  }

  .other-cases__img-blocks div:first-child{
    margin-bottom: 20px;
  }

  .project-en ~ .Footer .wrap--wide {
    padding-left: 20px;
    padding-right: 20px;
  }

  .other-cases__capt{
    margin-left: 0;
  }
}

@media (max-width: 767px){
  .project-en ~ .Footer{
    position: unset;
  }

  .project-en ~ .Footer .wrap--wide{
    position: relative;
  }

  /*.Scheme-head__capt.Scheme-head__capt--light{*/
  /*  display: none;*/
  /*}*/

  .project-en .Scheme-head__capt{
    width: auto;
  }

  .project-en .Scheme-link {
    display: block;
  }

  .project-en .Scheme-head__capt:before{
    display: block;
  }

  .project-en .Scheme-head__capt:before {
    font-size: 24px;
  }

  .project-en .Scheme-head__capt {
    padding-left: 50px;
  }
}

@media (max-width: 425px) {
  .project-en {
    padding-top: 80px;
  }

  .project-en .Scheme-head {
    display: block;
  }

  .project-en .Scheme-link {
    margin-left: 0;
  }

  .project-en-bg{
    background-position: center;
  }

  .img-blocks span {
    font-size: 24px;
  }

  .img-blocks.raiffCase span {
    width: 100%;
  }

  .project-en .wrap--in,
  .project-en .wrap--wide,
  .en-first-scr-txt{
    padding: 0 16px;
  }

  .client-img320{
    padding: 30px 16px 0 16px;
  }

  .project-en .Scheme-head {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .scr01-txt__dsc--block:nth-child(2) {
    margin-left: 20px;
  }

  .project-en-bg {
    margin-top: 0;
    margin-bottom: 20px;
  }

  .Scheme-head{
    display: none;
  }

  .laptop-img {
    max-width: 287px;
    width: 100%;
  }

  .client-img, .phone-img {
    max-width: 288px;
    width: 100%;
  }

  .case-en-info.info-txt-ul .wrap--in {
    padding: 50px 16px 0 16px;
  }

  .txt-par{
    font-size: 15px;
  }

  .txt-capt{
    margin-bottom: 15px;
  }

  .txt-par:not(:last-child) {
    margin-bottom: 15px;
  }

  .case-en-info.info-txt-ul .wrap--in {
    padding-top: 30px;
  }

  .laptop-img {
    padding: 40px 0;
  }

  .case-en-info.info-txt-ul .txt-par{
    margin-bottom: 24px;
  }

  .customer-area__txt {
    width: 95%;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .client-img{
    padding-top: 20px;
  }

  .customer-area__txt {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .phone-img{
    padding: 30px 0;
  }

  .case-en-info.info-txt-ul .wrap--in :last-child.phones__txt :nth-child(3),
  .case-en-info.info-txt-ul .wrap--in :last-child.phones__txt :nth-child(4),
  .case-en-info.info-txt-ul .wrap--in :last-child.phones__txt :nth-child(5) {
    display: inline-block;
    position: relative;
    margin-left: 40px;
  }

  .case-en-info.info-txt-ul .txt-par {
    width: auto;
  }

  .img-blocks a{
    padding: 0;
  }

  .other-cases__capt, .other-cases__img-blocks {
    padding: 0;
  }

  .other-cases__img-blocks div {
    width: 100%;
    height: 320px;
  }

  .other-cases__img-blocks {
    margin-top: 30px;
  }

  .project-en ~ .Footer .wrap--wide {
    padding-bottom: 27px;
    padding-top: 50px;
  }

  .other-cases__img-blocks{
    margin-bottom: -1px;
  }

  .project-en ~ .Footer .wrap--wide {
    padding-left: 0;
    padding-right: 0;
  }

  .img-blocks a{
    padding: 16px;
  }

  .case-en-info.info-txt-ul .wrap--in :last-child.phones__txt :nth-child(2) {
    margin-bottom: 16px;
  }

  .case-en-info.info-txt-ul .wrap--in :last-child.phones__txt :nth-child(5) {
    margin-bottom: 24px;
  }

  .other-cases__capt{
    padding: 0 16px;
  }

  .other-cases__img-blocks div:first-child{
    margin-bottom: 8px;
  }

}

@media (max-width: 320px) {
  .client-img{
    display: none;
  }

  .client-img320{
    display: block;
    width: 100%;
  }

}
/*<----------end SiemensCaseEn*/
/*<----------begin RaiffeisenCaseEn*/
.raiffeisen-en .Scheme-head__capt{
  width: 80%;
}

.raiffeisen-case-bg {
  background: url(/static/media/src_img_raiffeisen-first-screen.2b0b8909.jpg) no-repeat;
  background-size: cover;
}

.scenarios-txt-block-one :nth-last-child(-n+10),
.scenarios-txt-block-two :nth-last-child(-n+11){
  display: block;
  position: relative;
  margin-left: 37px;
  margin-bottom: 5px;
}

.scenarios-txt-block-one :nth-last-child(-n+10):before,
.scenarios-txt-block-two :nth-last-child(-n+11):before{
  position: absolute;
  content: '';
  top: 11px;
  left: -24px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #000000;
}

.implement-link{
  color: rgb(238, 68, 58);
  text-decoration: none;
}

.scenarios-img-block{
  background-color: #FFFFFF;
}

.raiff-scenarios-img{
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: 50px 96px;
}

.scenarios-img-block.wrap--indent{
  padding: 0 72px;
}

.case-en-grid-screen{
  background-color: #e4e4e4;
}

.case-en-grid-screen .wrap{
  display: flex;
  padding: 80px 72px 0 72px;
}

.grid-screen-one img,
.grid-screen-two img{
  display: block;
  width: 100%;
  height: auto;
}

.grid-screen-one{
  margin-right: 12px;
}

.grid-screen-two{
  margin-left: 12px;
}

.grid-screen-one img:not(:last-child),
.grid-screen-two img:not(:last-child){
  margin-bottom: 32px;
}

.scenarios-img-block img{
  padding-bottom: 0;
  background-color: #e4e4e4;
}

.security-img02,
.security-img03{
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: 60px 96px;
  background-color: #e4e4e4;
}

.wrap-img-block{
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 72px;
}

.siemens-img-wrap.wrap-img-block{
  background-color: #FFFFFF;
}

@media (max-width: 1024px) {

  .raiff-scenarios-img{
    padding: 50px 74px;
  }

  .security-img02,
  .security-img03{
    padding: 50px 74px;
  }

}

@media (max-width: 768px) {
  .scenarios-img-block.wrap--indent {
    padding: 0 40px;
  }

  .raiff-scenarios-img{
    padding: 50px;
  }

  .case-en-grid-screen .wrap{
    display: flex;
    padding: 50px 72px 0 72px;
  }

  .wrap-img-block{
    padding: 0 40px;
  }

  .security-img02,
  .security-img03 {
    padding: 50px;
  }
}

@media (max-width: 425px) {
  .raiffeisen-en .Scheme-head__capt {
    width: 100%;
  }

  .raiffeisen-case-bg{
    background-position: 50%;
  }

  .project-en-bg.raiffeisen-case-bg ~ .en-first-scr-txt > div:nth-child(2) {
    margin-top: 24px;
  }

  .project-en.raiffeisen-en .laptop-img{
    max-width: 393px;
  }

  .scenarios-img-block.wrap--indent{
    padding: 0;
  }

  .raiff-scenarios-img {
    padding: 20px 17px 0 17px;
  }

  .scenarios-img-block{
    margin: 42px 0 60px 0;
  }

  .case-en-grid-screen .wrap{
    display: flex;
    flex-direction: column;
    padding: 22px 16px 0 16px;
  }

  .grid-screen-one img,
  .grid-screen-two img:not(:last-child) {
    margin-bottom: 24px;
  }

  .grid-screen-one{
    margin-right: 0;
  }

  .grid-screen-two{
    margin-left: 0;
  }

  .case-en-grid-screen .wrap{
    margin-bottom: 60px;
  }

  .siemens-img-wrap{
    margin: 40px 0;
  }

  .security-img01 {
    padding: 30px 16px 35px 16px;
  }

  .wrap-img-block {
    padding: 0;
  }

  .security-img02,
  .security-img03{
    padding: 20px 17px;
  }

  .email{
    margin-top: 0;
  }

  .project-en ~ .Footer .wrap--wide .FooterCont {
    margin-left: 0;
    padding: 0 20px;
  }
}

@media (max-width: 320px){
  .raiffeisen-en .Scheme-head__capt.Scheme-head__capt--light {
    padding-left: 35px;
  }

  .project-en-bg.raiffeisen-case-bg ~ .en-first-scr-txt .scr01-txt__dsc{
    display: flex;
    flex-direction: column;
  }

  .project-en-bg.raiffeisen-case-bg ~ .en-first-scr-txt .scr01-txt__dsc--block:nth-child(2) {
    margin-left: 0;
    margin-top: 5px;
  }

  .email{
    margin-top: 20px;
  }
}
/*<----------end RaiffeisenCaseEn*/

