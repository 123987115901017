/*@font-face {*/
    /*font-family: 'HarmoniaBureauSansProCyr-SemiBd';*/
    /*src:url(./fonts/HarmoniaBureauSansProCyr.eot);*/
    /*src:url(./fonts/HarmoniaBureauSansProCyr.eot?#iefix) format("embedded-opentype"),url(./fonts/HarmoniaBureauSansProCyr.woff) format("woff"),url(./fonts/HarmoniaBureauSansProCyr.ttf) format("truetype"),url(./fonts/HarmoniaBureauSansProCyr.svg#HarmoniaBureauSansProCyr) format("svg");*/
    /*font-style: normal;*/
    /*font-weight: normal;*/
/*}*/

@font-face {
    font-family: 'HarmoniaSansProCyr-Regular';
    src:url(./fonts/HarmoniaSansProCyr-Regular.eot);
    src:url(./fonts/HarmoniaSansProCyr-Regular.eot?#iefix) format("embedded-opentype"),url(./fonts/HarmoniaSansProCyr-Regular.woff) format("woff"),url(./fonts/HarmoniaSansProCyr-Regular.ttf) format("truetype"),url(./fonts/HarmoniaSansProCyr-Regular.svg#HarmoniaSansProCyr-Regular) format("svg");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'HarmoniaSansProCyr-SemiBd';
    src:url(./fonts/HarmoniaSansProCyr-SemiBd.eot);
    src:url(./fonts/HarmoniaSansProCyr-SemiBd.eot?#iefix) format("embedded-opentype"),url(./fonts/HarmoniaSansProCyr-SemiBd.woff) format("woff"),url(./fonts/HarmoniaSansProCyr-SemiBd.ttf) format("truetype"),url(./fonts/HarmoniaSansProCyr-SemiBd.svg#HarmoniaSansProCyr-SemiBd) format("svg");
    font-style: normal;
    font-weight: normal;
}

@font-face {
  font-family: 'HarmoniaBureauSemiBold';
  src: url(./fonts/HarmoniaBureauSemiBold.eot);
  src: local('☺'), url(./fonts/HarmoniaBureauSemiBold.woff) format('woff'), url(./fonts/HarmoniaBureauSemiBold.ttf) format('truetype'), url(./fonts/HarmoniaBureauSemiBold.svg) format('svg');
  font-weight: normal;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css?family=Covered+By+Your+Grace');



